export class RadAI {
  static setup() {
    const form = document.getElementById('query-form');
    if (!form) {
      return;
    }

    const previousMessagesInput = document.getElementById('previous-messages');
    let previousMessagesArray = JSON.parse(previousMessagesInput.value);
    const includeReferences = document.getElementById('query_include_references');
    const responseContainer = document.getElementById('chat-box');
    const submitButton = form.querySelector('input[type="submit"]');

    previousMessagesArray.forEach(message => {
      const { role, content, time } = message;
      if (role === 'user') {
        responseContainer.innerHTML += RadAI.chatLeftTemplate('You', content, time || new Date().toLocaleTimeString());
      } else if (role === 'system') {
        responseContainer.innerHTML += RadAI.chatRightTemplate('RadBot', time || new Date().toLocaleTimeString());
        const chatElement = responseContainer.lastElementChild;
        const chatText = chatElement.querySelector('.chat-text');
        chatText.innerHTML = content;
      }
    });

    RadAI.scrollBottom();
  
    form.addEventListener('submit', (event) => {
      event.preventDefault();
  
      const query = document.getElementById('query_query').value;
      if (query.trim() === '') {
        responseContainer.textContent = 'Please provide a query';
        submitButton.disabled = false;
        return;
      }

      responseContainer.innerHTML += RadAI.chatLeftTemplate('You', query, new Date().toLocaleTimeString());
      responseContainer.innerHTML += RadAI.chatRightTemplate('RadBot', new Date().toLocaleTimeString());
      let chatElement = responseContainer.lastElementChild;
      let chatText = chatElement.querySelector('.chat-text');
  
      const url = `/queries/stream?query=${encodeURIComponent(query)}&include_references=${includeReferences.checked}`;
      const eventSource = new EventSource(url);
  
      eventSource.onmessage = (event) => {
        const spinner = chatText.querySelector('i.fa-spinner');
        if(spinner) {
          chatText.removeChild(spinner);
        }
        chatText.innerHTML += event.data;
        RadAI.scrollBottom();
      };
  
      eventSource.onerror = () => {
        eventSource.close();
      };

      document.getElementById('query_query').value = '';
    });
  }

  static scrollBottom() {
    const scrollContainer = document.getElementById('scroll-container');
    if (scrollContainer) {
      scrollContainer.scrollTop = scrollContainer.scrollHeight;
    }
  }

  static chatLeftTemplate(user, text, time) {
    return `<li class="chat-left mb-3 d-flex mms w-100">
              <div class="chat-avatar mr-3">
                  <i class="fa fa-user"></i>
                  <div class="chat-name mt-1">
                      ${user}
                  </div>
              </div>
              <div class="chat-text">
                  ${text}
              </div>
              <div class="chat-hour small d-flex align-items-center ml-2">
                  ${time}
              </div>
          </li>`;
  }

  static chatRightTemplate(user, time) {
    return `<li class="chat-right justify-content-end mb-3 d-flex w-100">
              <div class="chat-hour small d-flex align-items-center mr-2">
                  ${time}
              </div>
              <div class="chat-text">
                  <i class="fas fa-spinner fa-spin"></i>
              </div>
              <div class="chat-avatar ml-3">
                  <i class="fa fa-robot"></i>
                  <div class="chat-name mt-1">
                      ${user}
                  </div>
              </div>
          </li>`;
  }
}
